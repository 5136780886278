/* You can add global styles to this file, and also import other style files */
@import "~font-awesome/css/font-awesome.css";
@import 'ngx-toastr/toastr';

* {
    margin: 0;
    padding: 0;
    font-size: 12 px;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// To Show Arrows
.cursor_icon {
    cursor: pointer;
}

// To overcome dropdown in modal
.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 10000 !important;
}

// Items List
.list-group {
    max-height: 150px;
    overflow: hidden;
    overflow-y: auto;
    font-size: 14px; // Angular Update to 15
}

.back_icon_wrapper {
    position: relative;
    left: 48%;
    bottom: 10%;
}

.navigation_icon {
    font-size: 1.5rem;
    color: rgb(11,45,113);
}

.card-body {
    border: 2px solid rgb(11,45,113);
}

.card-footer {
    border: 2px solid rgb(11,45,113);
    border-top: 0;
}

.main-card-wrapper {
    border: 2px solid rgb(11,45,113);
}

.modal-title {
    font-size: 1rem;
}

// hide input file button
.hide-input-button {
    visibility: hidden;
    position: absolute;
    overflow: hidden;
    width: 0px;
    height:0px;
    border:none;
    margin:0;
    padding:0
}

// list modification
.list-group-item {
    padding: 2px;
}

//icons

.trash {
    font-size: 18px;
    color: #dc3545;
}

.success {
    font-size: 18px;
    color: #28a745;
    position: relative;
    left: 90%;
}

.error {
    font-size: 18px;
    color: #dc3545;
    position: relative;
    left: 90%;
}

.uploading {
    position: relative;
    left: 90%;
    color: rgb(11,45,113);
}

.download_spinner {
    position: relative;
    left: 36%;
    top:8%
}

.back-to-selection {
    color: rgb(11,45,113);
}

.card-header:first-child {
    border-radius: 0;
}

// Drop Down Changes Version 15 Update

.mat-mdc-form-field {
    bottom: 20px;
    position: relative;
}

.mdc-line-ripple {
    display: none;
}

.mat-mdc-select {
    border: 2px solid rgb(11,45,113);
    font-size: 16px;
    font-weight: 400
}

.mat-mdc-select-arrow-wrapper  {
    padding: 5px;
}

.mat-mdc-text-field-wrapper , .mat-mdc-form-field-focus-overlay {
    background-color: unset !important;
}

.mat-mdc-form-field-infix {
    padding-bottom: 0px !important;
    min-height: 3rem;
}

.mdc-text-field {
    padding: 0px !important;
}

.mat-mdc-select-value-text {
    letter-spacing: normal;
}

.mdc-menu-surface.mat-mdc-select-panel {
  max-height: 120px !important;
}

// text-box ui

.form-control {
  border-color: #0b2d71;
  height: calc(1.25em + 0.75rem);
}

.form-field-error {
  font-size: small;
  text-align: right;
}

.form-group {
  margin-bottom: 0.75rem;
}

.form-label {
  font-weight: 500;
}

// Bs Modal Related

.modal-header {
  background-color: rgb(11,45,113);
  color: white;
  padding: 8px;
  .close {
      color: white;
  }
}

.modal-footer {
  color: white;
  padding: 8px;
}

// Media Query

@media (min-width:1024px) {
    .main-wrapper {
        min-height: 450px;
    }
    .back_to_sub {
        position: absolute;
        left: 35%;
    }
}

@media (max-width:1023px) {
    .main-wrapper {
        min-height: 900px;
    }
    .back_to_sub {
        position: absolute;
        left: 25%;
    }
}
